<template>
  <div>
    <div class="menus-wrapper">
      <a-layout-header
        :style="{
          maxHeight: '64px',
          borderRadius: '5px'
        }"
      >
        <span>{{ subTitle }}</span>
      </a-layout-header>
    </div>
    <br>
    <a-layout-content class="content-wrapper">
      <div class="iotplt-content-box">
        <a-form :form="form" @submit="handleSubmit">
          <a-row :gutter="24">
            <a-col :span="8">
              <h3 class="title">工商信息</h3>

              <a-form-item label="企业全称">
                <a-input
                  placeholder="请输入企业全称"
                  v-decorator="['name', {
                    normalize: this.$lodash.trim,
                    rules: [{
                      required: true, message: '请输入企业全称'
                    }]
                  }]"
                />
              </a-form-item>

              <a-form-item label="工商营业执照注册号">
                <div slot="extra">
                  <span class="tip-text">请输入15位工商营业执照注册号，或三证合一后18位的统一社会信用代码 </span>
                  <a-popover placement="bottom">
                    <a class="tip-text">查看示例</a>
                    <template slot="content">
                      <img
                        src="@/assets/images/license_example.png"
                        style="width: 400px;"
                      >
                    </template>
                  </a-popover>
                </div>

                <a-input
                  v-decorator="['registration_no', {
                    rules:[{pattern: /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/, message: '工商营业执照注册号输入错误'}]
                  }]"
                />
              </a-form-item>

              <a-form-item label="企业工商营业执照" required>
                <span
                  slot="extra"
                  class="tip-text"
                >原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg .bmp .png格式照片，大小不超过10M</span>
                <upload-image
                  :is-add-watermark="isAddWatermark"
                  :default-file-list="businessLicense"
                  :accept="accept"
                  @change="handleBusinessLicenseChange"
                  v-decorator="['business_license', {rules: [
                    { validator: checkBusinessLicense }
                  ]
                  }]"
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <h3 class="title">法人信息</h3>

              <a-form-item label="法人姓名">
                <a-input
                  placeholder="请输入法人姓名"
                  v-decorator="['legal_name',{
                    normalize: this.$lodash.trim,
                    rules:[
                      { required: true, message: '请输入法人姓名' }
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item label="法人身份证号">
                <a-input
                  placeholder="请输入法人身份证号"
                  v-decorator="['identity_card',{
                    rules :[
                      { pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$/,
                        message: '法人身份证号输入错误' }
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item label="法人身份证照片(正面)">
                <span
                  slot="extra"
                  class="tip-text"
                >支持.jpg .jpeg .bmp .png格式照片，大小不超过10M</span>
                <upload-image
                  :is-add-watermark="isAddWatermark"
                  :default-file-list="frontIdentityCard"
                  :accept="accept"
                  @change="handleFrontIdentityCard"
                  v-decorator="['front_identity_card',{
                    rules:[
                      {validator: checkFrontIdentityCard}
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item label="法人身份证照片(反面)">
                <span
                  class="tip-text"
                  slot="extra"
                >支持.jpg .jpeg .bmp .png格式照片，大小不超过10M</span>
                <upload-image
                  :is-add-watermark="isAddWatermark"
                  :default-file-list="backIdentityCard"
                  :accept="accept"
                  @change="handleBackIdentityCardChange"
                  v-decorator="['back_identity_card',{
                    rules:[
                      {validator: checkBackIdentityCard}
                    ]
                  }]"
                />
              </a-form-item>
            </a-col>

            <a-col :span="8">
              <h3 class="title">设备信息</h3>

              <a-form-item label="终端设备名称">
                <a-input
                  placeholder="请输入终端设备名称"
                  v-decorator="['device_name']"
                />
              </a-form-item>

              <a-form-item label="终端设备照片">
                <div slot="extra">
                  <span class="tip-text">支持.jpg .jpeg .bmp .png格式照片，大小不超过10M；最多4张 </span>
                  <a-popover placement="bottom">
                    <a class="tip-text">查看示例</a>
                    <template slot="content">
                      <img
                        src="@/assets/images/device_example.png"
                        style="width: 300px;"
                      >
                    </template>
                  </a-popover>
                </div>
                <upload-image
                  :is-add-watermark="isAddWatermark"
                  :default-file-list="devices"
                  :accept="accept"
                  @change="handleDevices"
                  :max-count="4"
                  v-decorator="['devices',{
                    rules:[
                      {validator: checkDevices}
                    ]
                  }]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row type="flex" justify="center">
            <a-col>
              <a-form-item>
                <a-button
                  type="primary"
                  html-type="submit"
                  :loading="submitting"
                >
                  提交
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-layout-content>
  </div>
</template>

<script>
import { findRegisteredAgentEnterpriseQualificationpUdateForm, updateRegisteredAgentEnterpriseQualification } from '@/api/enterprise_qualification'
import UploadImage from '@/components/Upload/Image'
export default {
  name: 'EditRegisteredAgentEnterpriseQualification',
  components: { UploadImage },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'update_enterprise_qualification' }),
      data: {},
      subTitle: '您正在对资质进行修改',
      registeredAgentId: this.$store.getters.userRegisteredAgentId,
      userId: this.$store.getters.userId,
      submitting: false,
      businessLicense: [], // 企业工商营业执照
      frontIdentityCard: undefined, // 法人身份证（正面）
      backIdentityCard: undefined, // 法人身份证（背面）
      devices: [], // 终端设备
      accept: '.jpg,.jpeg,.bmp,.png',
      isAddWatermark: true, // 是否添加水印
      enterpriseQualificationId: 0
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findRegisteredAgentEnterpriseQualificationpUdateForm({ registered_agent_id: this.registeredAgentId }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.enterpriseQualificationId = res.data.id
          this.form.setFieldsValue({
            name: this.data.name,
            registration_no: this.data.registration_no,
            legal_name: this.data.legal_name,
            identity_card: this.data.identity_card,
            device_name: this.data.device_name
          })
          this.businessLicense = this.data.business_license
          this.frontIdentityCard = Object.keys(this.data.front_identity_card).length > 0 ? this.data.front_identity_card : undefined
          this.backIdentityCard = Object.keys(this.data.back_identity_card).length > 0 ? this.data.back_identity_card : undefined
          this.devices = this.data.devices
        }
      })
    },

    // 检查是否上传工商营业执照
    checkBusinessLicense(rule, value, callback) {
      if (!this.businessLicense[0] || this.businessLicense[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请上传企业工商营业执照')
        return
      }

      callback()
    },

    // 检查法人身份证照片（正面）
    checkFrontIdentityCard(rule, value, callback) {
      if (this.frontIdentityCard && this.frontIdentityCard[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('法人身份证照片（正面）上传错误')
        return
      }

      callback()
    },

    // 检查法人身份证照片（背面）
    checkBackIdentityCard(rule, value, callback) {
      if (this.backIdentityCard && this.backIdentityCard[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('法人身份证照片（背面）上传错误')
        return
      }

      callback()
    },

    // 检查终端设备照片
    checkDevices(rule, value, callback) {
      for (const key in this.devices) {
        if (this.devices[key] && this.devices[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('终端设备照片上传错误')
          return
        }
      }

      callback()
    },

    handleBusinessLicenseChange(fileList) {
      this.businessLicense = fileList
    },

    handleFrontIdentityCard(fileList) {
      if (Object.keys(fileList).length > 0) {
        this.frontIdentityCard = fileList
      } else {
        this.frontIdentityCard = undefined
      }
    },

    handleBackIdentityCardChange(fileList) {
      if (Object.keys(fileList).length > 0) {
        this.backIdentityCard = fileList
      } else {
        this.backIdentityCard = undefined
      }
    },

    handleDevices(fileList) {
      this.devices = fileList
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.$lodash.find(this.businessLicense, { status: 'uploading' }) ||
        this.$lodash.find(this.frontIdentityCard, { status: 'uploading' }) ||
        this.$lodash.find(this.backIdentityCard, { status: 'uploading' }) ||
        this.$lodash.find(this.devices, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }

      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            registered_agent_id: this.registeredAgentId,
            business_license: this.businessLicense[0].response,
            devices: this.$lodash.map(this.devices, 'response')
          }
          if (this.frontIdentityCard === undefined) {
            delete data.front_identity_card
          } else {
            data.front_identity_card = this.frontIdentityCard[0].response
          }
          if (this.backIdentityCard === undefined) {
            delete data.back_identity_card
          } else {
            data.back_identity_card = this.backIdentityCard[0].response
          }
          updateRegisteredAgentEnterpriseQualification(data).then((res) => {
            if (res.code === 0) {
              this.$router.push({ name: 'registered_agent_home' })
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content-wrapper {
  padding: 0 50px 30px 50px;
  overflow: auto;
  height: calc(100vh - 146px);
  .img {
    float: left;
    margin: 10px;
    width: 100px;
    height: 100px;
    max-width: 100%;
    min-width: 100px;
    object-fit: cover;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
}

.menus-wrapper {
  z-index: 1;
  width: 100%;
  padding: 16px 50px 0 50px;
  font-size: 16px;
  .iotplt-update-enterprise_qualifications-button {
    float: right;
  }
}
</style>

